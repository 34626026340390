<template>
    <div class="forgot-password-page">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="my-5 p-3">
                                    
                        <h1>ZAPOMNIAŁEM HASŁA</h1>
                        
                        <div v-if="isSendReset">
                            Na podany adres e-mail została wysłana wiadomość z linkiem do zmiany hasła. <br />
                            Jeśli nie dostałeś wiadomości koniecznie sprawdź folder "Niechciane" lub "Spam".
                        </div> 
                        <div v-else class="form-container">
                            <form method="post" @submit.prevent="beforeReset" action="">
                                <inputEmail v-model="form.email" name="email" label="E-mail" :error="validation.firstError('form.email')" />

                                <dismissAlert ref="errorAlert" />

                                <div class="text-center">
                                    <buttonLoader type="submit" class="btnLong" variant="success" :isLoading="inProgress" label="Zmień hasło" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import SimpleVueValidation from 'simple-vue-validator';
const Validator = SimpleVueValidation.Validator;

export default {
    name: 'forgotPassword',
    data() { 
        return {
            inProgress: false,
            isSendReset: false,
            form: {
                email: null
            }
        }
    },
    validators: {
        'form.email': function (value) {
            return Validator.value(value).required().minLength(5).maxLength(255).email();
        }
    },
    methods: {
        beforeReset(){
            this.inProgress = true;
            this.$validate().then( this.sendReset );
        },
        sendReset(success){
            if (success) {
                this.$store.dispatch('auth/forgotPassword', this.form)
                .then( () => {
                    this.inProgress = false;
                    this.isSendReset = true;
                }).catch( error =>  {
                    this.inProgress = false;
                    this.$refs.errorAlert.alertShowDanger( error );
                });
            } else {
                this.inProgress = false;
            }

        }
    }

}
</script>